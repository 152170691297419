<template>
  <div>
    <div v-if="maintenance">
      <div style="text-align: center; margin-top:10%">
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{message}}
         </b-button>
      </div>
    </div>
    <div v-else>
      <metabase class="metabase-frame" :url="iframeUrl"></metabase>
    </div>
  </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import Metabase from "@/view/content/metabase/metabase.vue";

export default {
  name: "Stats",
  components: {
    Metabase
  },
  data() {
    return {
        dashboard : 1,
        titled : false,
        border : false,
        iframeUrl : '',
        maintenance : false,
        message : "Stats are under maintenance, please wait a bit"
      }
  },
  methods: {
    async metabase(){
      let response = await ApiService.get(`metabase`,`?apikey=${this.userdata.apikey}&dashboard=${this.dashboard}&titled=${this.titled}&border=${this.border}`)
      this.iframeUrl = response.data
    }
  },
  computed: {
    ...mapGetters(["userdata"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Stats" }]);
    this.metabase()
  }
};
</script>

<style>

.metabase-frame{
  max-height: 90vh;
  overflow-y: hidden;
}
</style>
